<template>
    <main>
        <modal-informacion-usuario :usuario="usuario"></modal-informacion-usuario>
        <page-header-compact class="mb-2">
            Editar Resultados Citologia
            <template v-slot:button>
                <router-link :to="{ name: 'citologias.index'}"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                        Regresar al Listado de citologias <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card">
                <card-informacion-usuario :usuario="usuario"></card-informacion-usuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="row" v-for="(seccion, i) in secciones" :key="`secc_${i}`">
                        <div class="col-12">
                            <h6 class="h6 mt-2 border-bottom">{{ seccion.nombre }}</h6>
                            <div v-for="(item, i) in seccion.items" :key="`${i}_item`">
                                <checkBoxUi 
                                    :name="item.descripcion" 
                                    v-model="item.selected" 
                                    :id="`check${i}${seccion.id}`"
                                    :color="'#0d6efd'"
                                    >
                                </checkBoxUi>
                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <h6 class="h6 mt-2 border-bottom">{{ seccion.nombre }}</h6>
                            <div class="form-check" v-for="(item, i) in seccion.items" :key="`${i}_item`">
                                <input class="form-check-input" type="checkbox" v-model="item.selected"
                                    :id="`check${i}${seccion.id}`">
                                <label class="form-check-label" :for="`check${i}${seccion.id}`">
                                    {{ item.descripcion }}
                                </label>
                            </div>
                        </div> -->
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label>Fecha Lectura</label>
                            <input type="date" class="form-control form-control-sm" v-model="fecha">
                        </div>
                        <div class="col-8">
                            <label>Observaciones</label>
                            <textarea class="form-control" v-model="observaciones"></textarea>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-lg btn-success shadow-sm" @click="save()">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import PageHeaderCompact from "@/components/layouts/content/page-header-compact";
import ModalInformacionUsuario from "@/views/historias/base/informacionUsuario/modalInformacionUsuario";
import usuarioService from "@/services/usuarioService";
import tomaMuestrasService from "@/services/tomaMuestrasService";
import CardInformacionUsuario from "@/views/historias/base/informacionUsuario/cardInformacionUsuario";
import seccionCitologiaService from "@/services/seccionCitologiaService";
import citologiaService from "@/services/citologiaService";
import Swal from "sweetalert2";
import { isEmpty } from 'lodash';
import checkBoxUi from '@/components/common/checkBox/checkBoxUi';
export default {
    components: { CardInformacionUsuario, ModalInformacionUsuario, PageHeaderCompact,checkBoxUi },
    data() {
        return {
            id_toma_muestra: '',
            toma_de_muestra: {},
            observaciones: '',
            fecha: '',
            usuario: {},
            secciones: [],
            id_resultado: ''
        }
    },
    async created() {
        this.id_toma_muestra = this.$route.params.id;
        this.toma_de_muestra = (await tomaMuestrasService.show(this.id_toma_muestra))?.data;
        this.usuario = (await usuarioService.show(this.toma_de_muestra.historia.id_usuario))?.data;
        this.secciones = (await seccionCitologiaService.index())?.data;

        const resultados = await citologiaService.show(this.id_toma_muestra);
        this.fecha=resultados.data.fecha;
        this.observaciones=resultados.data.observaciones;
        this.id_resultado=resultados.data.id;
        this.secciones.forEach((x, i) => {
            this.secciones[i].items = x.items.map(y => {
                let respuesta = false;
                if (!isEmpty(resultados.data)) {
                    respuesta = resultados.data.items.some(r => r.id_item_seccion === y.id)
                }
                return {
                    ...y,
                    selected: respuesta
                }
            });
        });
    },
    methods: {
        async save() {
            try {
                this.LoaderSpinnerShow();

                let items = [];

                this.secciones.forEach((x) => {
                    items.push(...x.items.filter(y => y.selected));
                });

                let data = {
                    id_toma_muestra: this.id_toma_muestra,
                    observaciones: this.observaciones,
                    fecha: this.fecha,
                    items: items,
                    id_resultado: this.id_resultado
                };

                await citologiaService.update(data);

                this.LoaderSpinnerHide();

                await Swal.fire('Exito', 'resultados guardados con exíto', 'success');

                this.$router.push({
                    name: 'citologias.index'
                });

            } catch (e) {
                this.LoaderSpinnerHide();
                console.error(e);
                Swal.fire('Ups!', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        }
    }
}
</script>

<style scoped></style>